
.icon-logout:before { content: '\e800'; } /* '' */
.icon-mail:before { content: '\e801'; } /* '' */
.icon-arrow-down-mini:before { content: '\e802'; } /* '' */
.icon-lock:before { content: '\e803'; } /* '' */
.icon-edit:before { content: '\e804'; } /* '' */
.icon-trash:before { content: '\e805'; } /* '' */
.icon-user:before { content: '\f061'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
