@font-face {
  font-family: 'SF Pro Display Medium';
  src: url('../assets/fonts/SFProDisplay-Medium/SFProDisplay-Medium.woff2') format('woff2'),
  url('../assets/fonts/SFProDisplay-Medium/SFProDisplay-Medium.woff') format('woff'),
  url('../assets/fonts/SFProDisplay-Medium/SFProDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: url('../assets/fonts/SFProDisplay-Bold/SFProDisplay-Bold.woff2') format('woff2'),
  url('../assets/fonts/SFProDisplay-Bold/SFProDisplay-Bold.woff') format('woff'),
  url('../assets/fonts/SFProDisplay-Bold/SFProDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro Display Light';
  src: url('../assets/fonts/SFProDisplay-Light/SFProDisplay-Light.woff2') format('woff2'),
  url('../assets/fonts/SFProDisplay-Light/SFProDisplay-Light.woff') format('woff'),
  url('../assets/fonts/SFProDisplay-Light/SFProDisplay-Light.ttf') format('truetype');
}
