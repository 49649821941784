@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@mixin text-align-when-sidebar-active($breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      .text#{$infix}-left {
        text-align: left !important;
      }
      .text#{$infix}-right {
        text-align: right !important;
      }
      .text#{$infix}-center {
        text-align: center !important;
      }
    }
  }
}
