@import "vars";

@mixin spacing-when-sidebar-active() {
  @each $breakpoint in map-keys($grid-breakpoints-with-sidebar) {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints-with-sidebar) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints-with-sidebar);

      @each $prop, $abbrev in (margin: 'm', padding: 'p') {
        @each $size, $length in $spacers {
          .#{$abbrev}#{$infix}-#{$size} {
            #{$prop}: $length !important;
          }
          .#{$abbrev}t#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-top: $length !important;
          }
          .#{$abbrev}e#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-right: $length !important;
          }
          .#{$abbrev}b#{$infix}-#{$size},
          .#{$abbrev}y#{$infix}-#{$size} {
            #{$prop}-bottom: $length !important;
          }
          .#{$abbrev}s#{$infix}-#{$size},
          .#{$abbrev}x#{$infix}-#{$size} {
            #{$prop}-left: $length !important;
          }
        }
      }
    }
  }
}


