$headerHeight: 64px;
$bgColor: #F7F9FA;
$sidebarWidth: 256px;
$mobile: 768px;
$mobile-sm: 375px;

$grid-breakpoints-with-sidebar: (
  xs: 0,
  sm: 576px + $sidebarWidth,
  md: 768px + $sidebarWidth,
  lg: 992px + $sidebarWidth,
  xl: 1200px + $sidebarWidth
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
