@import "vars";

data-table {
  table {
    th {
      padding-right: 10px;
      font-size: 12px;
      font-weight: 500;
    }

    td, th {
      border-bottom: 1px solid #f0f3ff;
    }

    td {
      font-size: 13px;
      padding-right: 10px;
    }

    tr {
      height: 48px;

      td:not(:last-child) {
        padding-right: 10px;
      }

      td:first-child, th:first-child {
        padding-left: 10px;
      }

      &:hover {
        background: #fafbfc;
      }
    }
  }
}

@mixin mobile-table($width:map-get($grid-breakpoints, 'md')) {
  @media (max-width: $width) {
    data-table:not(.desktop) {
      table {

        thead {
          display: none;
        }

        tr {
          border-bottom: 1px solid #ddd;

          > td:first-child {
            margin-top: 5px;
          }

          td {
            border: none;
            display: block;
            width: 100% !important;
            padding: 7px 10px !important;
          }
        }
      }
    }
  }
}

@mixin cell-content-sm($width:map-get($grid-breakpoints, 'md')) {
  @media (max-width: $width) {
    cell-content {
      .header {
        display: inline-block!important;
        width: 30%;
      }

      .content {
        display: inline-block!important;
        max-width: 67%;
        vertical-align: middle;
      }
    }
  }
}

@mixin cell-content-xs($width:500px) {
  @media (max-width: $width) {
    cell-content:not(.force-2-cols) {
      .content {
        margin-top: 5px;
        max-width: 99%;
        margin-right: auto;
      }

      .header {
        width: 99%;
      }
    }
  }
}
