@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "styles/spinner/spinner";
@import "styles/icons/fontello.css";
@import "styles/icons/fontello-codes.css";
@import "styles/vars";
@import "styles/cols-sidebar";
@import "styles/spacing-sidebar";
@import "styles/fonts";
@import "styles/data-table";
@import "styles/text-align-when-sidebar-active";

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $bgColor;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::placeholder {
  color: #D3D3D3;
  opacity: 1;
}

.form-control {
  outline: none;
  width: 100%;
  height: 50px;
  padding: 0 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.form-control-label {
  margin-bottom: 5px;
  display: block;
}

input.ng-invalid.ng-touched {
  border-color: red;
  border-radius: 4px;
}

.mat-button-base.kt-spinner.kt-spinner--right {

  &:before {
    margin-right: 10px;
  }

  .mat-button-wrapper {
    padding-right: 15px;
  }

  &.no-padding {
    .mat-button-wrapper {
      padding-right: 0;
    }
  }

}

.mat-main {
  background-color: #FFA500;
  color: white;
}

.success {
  background-color: #5B9BD5;
  color: black;
}

.filters {
  margin-top: -15px;

  > div {
    margin-top: 15px;
  }

  .control-label {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
    display: block;
  }
}

.actions-column {
  width: 1%;
  padding-left: 10px;

  i {
    font-size: 1.2rem;
  }
}

@include mobile-table();
@include cell-content-sm();
@include cell-content-xs();

.sidebar:not(.mobile) {
  @include make-sb-grid-columns();
  @include spacing-when-sidebar-active();
  @include mobile-table(map-get($grid-breakpoints, 'md') + $sidebarWidth);
  @include cell-content-sm(map-get($grid-breakpoints, 'md') + $sidebarWidth);
  @include text-align-when-sidebar-active($grid-breakpoints-with-sidebar);
}



