@font-face {
  font-family: 'fontello';
  src: url('../../assets/icons/fontello.eot?11642244');
  src: url('../../assets/icons/fontello.eot?11642244#iefix') format('embedded-opentype'),
  url('../../assets/icons/fontello.woff2?11642244') format('woff2'),
  url('../../assets/icons/fontello.woff?11642244') format('woff'),
  url('../../assets/icons/fontello.ttf?11642244') format('truetype'),
  url('../../assets/icons/fontello.svg?11642244#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
