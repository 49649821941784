@import "colors";
@import "sizes";


@function kt-get-warning($warn, $get, $key) {
  @if $get == null {
    @warn "Map has no value for key search `#{$warn}`";
  } @else if type-of($get) != 'map' {
    @warn "Non-map value found for key search `#{$warn}`, cannot search for key `#{$key}`";
  }
  @return null;
}

@function kt-get($map, $keys...) {
  @if length($keys) == 1 {
    $keys: nth($keys, 1);
  }

  $warn: "#{nth($keys, 1)}";
  $length: length($keys);
  $get: map-get($map, nth($keys, 1));

  @if $length > 1 {
    @for $i from 2 through $length {
      @if $get != null and type-of($get) == 'map' {
        $warn: $warn + "->#{nth($keys, $i)}";
        $get: map-get($get, nth($keys, $i));

        @if $get == null {
          @return null;
        }
      } @else {
        @return kt-get-warning($warn, $get, nth($keys, $i));
      }
    }
  }

  @return $get;
}

@mixin kt-spinner-size($size) {
  &:before {
    width: kt-get($spinner-sizes, $size);
    height: kt-get($spinner-sizes, $size);
    margin-top: -(kt-get($spinner-sizes, $size)/2);
  }

  &.kt-spinner--center {
    &:before {
      left: 50%;
      margin-left: -(kt-get($spinner-sizes, $size)/2);
    }
  }

  &.kt-spinner--left {
    &:before {
      left: 0;
      right: auto;
    }
  }

  &.kt-spinner--right {
    &:before {
      left: auto;
      right: 0;
    }
  }
}

@mixin kt-spinner-skin($color) {
  &:before {
    border: 2px solid $color;
    border-right: 2px solid transparent;
  }
}

.kt-spinner {
  position: relative;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
  }

  // Sizes
  &.kt-spinner--sm {
    @include kt-spinner-size(sm);
  }

  @include kt-spinner-size(md);

  &.kt-spinner--lg {
    @include kt-spinner-size(lg);
  }

  // Default Style
  &:before {
    animation: kt-spinner .5s linear infinite;
  }

  // State Colors
  @each $name, $color in $spinner-colors {
    &.kt-spinner--#{$name} {
      &:before {
        border: 2px solid $color;
        border-right: 2px solid transparent;
      }
    }
  }

  // Input mode
  &.kt-spinner--input {
    &.kt-spinner--right {
      &:before {
        left: auto;
        right: 1rem;
      }
    }

    &.kt-spinner--left {
      &:before {
        right: auto;
        left: 1rem;
      }
    }
  }

  // Spinner Default
  &.kt-spinner--v2 {
    // Default Style
    &:before {
      animation: kt-spinner .5s linear infinite;
    }

    // State Colors
    @each $name, $color in $spinner-colors {
      &.kt-spinner--#{$name} {
        &:before {
          border: 2px solid lighten($color, 30%);
          border-top-color: darken($color, 3%);
        }
      }
    }
  }
}

// Animations
@keyframes kt-spinner {
  to {
    transform: rotate(360deg);
  }
}
